import React from 'react';

import Suits from './suits';

const Landing = () => (
	<div className='landing-page'>
		<p>NYC-based training for live low-stakes poker games.</p>
		<p>Top up your stack.</p>
		<p>Top up your game.</p>
		<div className='suits'>
			<Suits />
		</div>
	</div>
);

export default Landing;
