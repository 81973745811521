import React from 'react';

import Layout from '../components/layout';
import Landing from '../components/landing';

const IndexPage = () => (
	<Layout>
		<Landing />
	</Layout>
);

export default IndexPage;

export const Head = () => <title>Top Up Poker - Live Poker Training</title>;
